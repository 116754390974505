import styled from "@emotion/styled"
import { TextElement } from "@nutrafol/nutrafol-ui-kit/dist/TextElement"
import theme from "@nutrafol/nutrafol-ui-kit/styles/theme"

export const SearchIconContainer = styled.div`
  margin-right: 8px;
  @media (min-width: 768px) and (max-width: 800px) {
    margin-right: 4px;
  }
  @media (min-width: 1024px) {
    margin-right: 25px;
  }

  // added incase tailwind purges styles for hidden bc it's applied in a ternary

  &.hidden {
    display: none;
  }
`

export const SearchIconContainerVariant = styled.div`


  &.hidden {
    display: none;
  }
`

export const Disclaimer = styled.div`
  margin-top: auto;

  @media (min-width: 768px) {
    padding: 0 0 24px;
  }

  @media (max-width: 767px) {
    margin: -24px -16px;
  }

  .footer-box {
    background-color: #f8f8f5;
    width: 100%;
    padding: 16px 32px;
  }
`

export const QuizCTAWrapper = styled.div`
  @media (max-width: 767px) {
    position: sticky;
    bottom: 0;
    background-color: white;
    width: 100%;
    padding: 16px;
    transition: box-shadow 0.1s ease-in-out;
    &.scroll-shadow {
      box-shadow:
        0px -2px 4px 0px rgba(0, 0, 0, 0.04),
        0px -6px 10px 0px rgba(0, 0, 0, 0.02),
        0px -10px 15px 0px rgba(0, 0, 0, 0.04);
    }
    button {
      margin: 0;
    }
  }
`

export const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;

  position: fixed;
  overflow: auto;
  top: 0;
  bottom: 0;
  width: 100%;
  background-color: white;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.2);
  z-index: 9;

  @media (min-width: 768px) {
    position: absolute;
    bottom: initial;
    height: 100dvh;
    top: initial;
    height: calc(100dvh - 140px);
    margin-top: 4px;
  }

  @media (min-width: 1024px) {
    height: calc(100dvh - 190px);
  }

  .close-button {
    display: flex;
    align-items: center;
    margin-left: auto;
    svg {
      width: 24px;
      height: 24px;
      @media (min-width: 1024px) {
        width: 32px;
        height: 32px;
      }
      path {
        fill: ${theme.wild.color.master.base.alt.alt};
      }
    }
  }

  .header-button {
    display: inline-flex;
    align-items: center;
    height: 55px;
    padding: 16px;

    @media (min-width: 1024px) {
      height: 48px;
      padding: 8px 0;
    }

    svg {
      width: 24px;
      height: 24px;
      @media (min-width: 1024px) {
        width: 32px;
        height: 32px;
      }
    }
  }

  .header-button-text {
    color: ${theme.wild.color.master.base.alt.alt};
    font-size: 14px;
    @media (min-width: 1024px) {
      font-size: 16px;
    }
  }

  .search-result-text {
    color: #677574;
    margin-top: 8px;
    font-size: 12px;
    @media (min-width: 768px) {
      margin-top: 16px;
      font-size: 16px;
    }
  }

  .suggested-title {
    font-family: ${theme.wild.font.grilli.book};
    color: ${theme.wild.color.master.base.black};
    font-size: 24px;
  }

  .view-more-faq-button {
    font-size: 14px;
    line-height: 1.5;
    @media (min-width: 1200px) {
      font-size: 16px;
    }
  }

  .no-results {
    display: flex;
    flex-direction: column;
    text-align: center;
    color: #22202f;
    background-color: #f8f8f5;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .no-results-subcopy.textelement[class*="body--large"] {
    @media (max-width: 1023px) {
      font-size: 12px;
      line-height: 1.3;
    }
  }

  .text-link {
    font-size: 14px;
    color: ${theme.wild.color.master.primary.default};

    @media (min-width: 1200px) {
      font-size: 16px;
    }
  }

  .suggested-hit {
    font-size: 14px;
    color: ${theme.wild.color.master.primary.default};
    @media (min-width: 1024px) {
      font-size: 18px;
    }
  }

  .suggested-search {
    padding-bottom: 40px;
  }

  @media (max-width: ${theme.mediaqueries.small}) {
    .suggested-title {
      font-size: 14px;
    }
  }

  .article-body-text p {
    margin-bottom: 16px;
    color: ${theme.wild.color.master.base.black};
    font-size: 14px;
    line-height: 1.3;

    @media (min-width: 768px) {
      font-size: 16px;
    }
  }
`

export const HeaderContainer = styled.div`
  padding: 0 16px;

  @media (max-width: 767px) {
    position: sticky;
    z-index: 9;
    top: 0;
    left: 0;
    width: 100%;
    background-color: white;
    transition: box-shadow 0.1s ease-in-out;
    &.scroll-shadow {
      box-shadow:
        0px 2px 4px 0px rgba(0, 0, 0, 0.04),
        0px 6px 10px 0px rgba(0, 0, 0, 0.02),
        0px 10px 15px 0px rgba(0, 0, 0, 0.04);
    }
  }

  @media (min-width: 768px) {
    padding: 0 24px;
  }

  @media (min-width: 1024px) {
    padding: 16px 40px 0;
  }

  @media (min-width: 1200px) {
    padding: 16px 80px 0;
  }
`

export const InputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  .search-input {
    background-color: ${theme.color.beige};
    color: ${theme.wild.color.master.base.black};
    width: 100%;
    border: 1px solid #90a9a6;
    outline: none;
    padding: 16px 88px 16px 16px;
    font-family: ${theme.wild.font.grilli.book};
    font-size: 18px;
    line-height: 1;
    height: 24px;
    box-sizing: content-box;

    @media (min-width: 1024px) {
      height: 32px;
      font-size: 24px;
      padding: 24px 112px 24px 24px;
    }

    &::placeholder {
      color: ${theme.wild.color.master.base.alt.disabled};
      font-family: ${theme.wild.font.grilli.italic};
    }
  }

  .search-controls {
    position: absolute;
    right: 16px;
    display: flex;
    align-items: center;
    gap: 8px;

    svg {
      width: 24px;
      height: 24px;
    }

    @media (min-width: 1024px) {
      right: 24px;
      gap: 16px;

      svg {
        width: 32px;
        height: 32px;
      }
    }
  }
`

export const SearchResultsContainer = styled.div`
  flex: 1;
  position: relative;

  padding: 24px 16px;

  @media (min-width: 768px) {
    padding: 40px 24px 0;
  }

  @media (min-width: 1024px) {
    padding: 40px 40px 0;
  }

  @media (min-width: 1200px) {
    padding: 40px 80px 0;
  }

  .search-subtitle {
    position: relative;
  }

  .images-container {
    flex: 1;
    @media (min-width: 1024px) {
      max-width: 800px;
    }
  }

  .skin-text {
    font-size: 14px;
  }

  .search-category {
    overflow-x: auto;
  }

  @media (max-width: ${theme.mediaqueries.small}) {
    .images-container {
      overflow: hidden;
    }

    .search-content {
      margin-top: 10px;
      overflow: auto;
    }

    .skin-text {
      font-size: 12px;
    }

    .no-results-content {
      flex-direction: column;
    }

    .no-results-products {
      display: inline-flex;
    }
  }
`

export const SuggestedSearchButton = styled.button`
  max-width: 188px;
  min-width: 135px;
  width: 100%;
  @media (max-width: 480px) {
    width: calc(50% - 8px);
  }
`

export const ProductWrapper = styled.div`
  max-width: 188px;
  min-width: 135px;
  width: 100%;
`

export const SearchItemImageContainer = styled.div`
  width: 100%;
  background-color: ${theme.wild.color.master.base.secondary};
  object-fit: contain;

  img {
    width: 100%;
    margin: 0 auto;
    object-fit: cover;
    aspect-ratio: 47 / 36;
  }
`

export const SearchImageText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;

  .search-subtitle {
    color: #677574;
    font-size: 14px;
  }
`

export const SearchTitle = styled(TextElement)`
  font-family: ${theme.wild.font.grilli.book};
  color: ${theme.wild.color.master.base.black};
  font-size: 14px;
  line-height: 1.3;
  margin-bottom: 8px;

  @media (min-width: 767px) {
    font-size: 18px;
  }

  @media (min-width: 1200px) {
    font-size: 24px;
    margin-bottom: 16px;
  }
`

export const SearchSubtitle = styled(TextElement)`
  font-family: ${theme.wild.font.dinamo.book};
  color: ${theme.wild.color.master.base.alt.alt};
  font-size: 12px;
  margin-bottom: 4px;

  @media (min-width: 767px) {
    font-size: 14px;
    line-height: 20px;
  }
`

export const ProductDescriptionCard = styled.button`
  cursor: pointer;

  .product-title {
    color: ${theme.wild.color.master.base.black};
    @media (min-width: 1024px) {
      color: ${theme.wild.color.master.primary.default};
    }
  }
`

export const Products = styled.div`
  display: flex;
  gap: 8px;
  overflow-x: auto;

  @media (min-width: 1024px) {
    overflow: initial;
  }

  @media (min-width: 1280px) {
    gap: 16px;
  }
`

export const ResultsContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 32px;
  padding-bottom: 40px;

  @media (min-width: 768px) {
    gap: 24px;
  }

  @media (min-width: 1024px) {
    justify-content: space-between;
    flex-direction: row;
    gap: 40px;
  }

  @media (min-width: 1200px) {
    gap: 40px;
  }

  main {
    @media (min-width: 1024px) {
      flex: 1;
    }

    @media (max-width: 1023px) {
      order: ${(props) => (props.isReversed ? 2 : 1)};
    }
  }

  aside {
    @media (max-width: 1023px) {
      order: ${(props) => (props.isReversed ? 1 : 2)};
    }

    @media (min-width: 1024px) {
      width: 275px;
    }

    @media (min-width: 1200px) {
      width: 325px;
    }

    @media (min-width: 1425px) {
      width: 375px;
    }
  }
`

export const DefaultSearchContainer = styled.div`
  width: 100%;

  .button-small {
    font-size: 12px;
    line-height: 1.4;
    @media (min-width: 768px) {
      font-size: 14px;
    }
  }
`
